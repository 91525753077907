exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-components-templates-final-page-template-js": () => import("./../../../src/components/templates/FinalPageTemplate.js" /* webpackChunkName: "component---src-components-templates-final-page-template-js" */),
  "component---src-components-templates-petalos-template-js": () => import("./../../../src/components/templates/PetalosTemplate.js" /* webpackChunkName: "component---src-components-templates-petalos-template-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-admin-js": () => import("./../../../src/pages/admin.js" /* webpackChunkName: "component---src-pages-admin-js" */),
  "component---src-pages-circulo-base-js": () => import("./../../../src/pages/circulo-base.js" /* webpackChunkName: "component---src-pages-circulo-base-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-intro-text-js": () => import("./../../../src/pages/intro-text.js" /* webpackChunkName: "component---src-pages-intro-text-js" */)
}

